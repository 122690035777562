@import "../../../variables";

.openSettings {
    position: absolute;
    top: 1rem;
    right: 1rem;
    //transform: translateX(400%);
    box-sizing: border-box;
    padding: .5rem;
    cursor: pointer;

    svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: $font-color;
        opacity: .3;
        transition: all .2s ease;
    }

    &:hover {
        svg {
            opacity: 1;
        }
    }
}

@media only screen and (max-width: 450px) {
    .openSettings {
        svg {
            width: 2.5rem;
            height: 2.5rem;
        }
    }
}