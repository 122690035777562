.nesgames {
    .filter {
        margin: 0 1rem 1rem 1rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .options {
            display: flex;
            justify-content: flex-start;
            max-height: 0;
            overflow: hidden;
            opacity: 0;
            transform-origin: top;
            transform: rotateX(90deg);
            transition: max-height .2s ease-out, opacity .1s ease-out, transform .2s ease;

            &.expanded {
                transform: none;
                max-height: 15rem;
                opacity: 1;
            }
        }

        .options-toggle {
            opacity: .5;
            transition: opacity .2s ease;

            img {
                width: 3rem;
            }

            &:hover {
                opacity: 1;
            }
        }
    }
}

@media only screen and (max-width: 700px) {
    .nesgames {
        .filter {
            div {
                flex-direction: column;
            }

            .options {
                &.expanded {
                    max-height: 20rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .nesgames {
        .filter {
            flex-direction: column-reverse;

            .options-toggle {
                align-self: flex-end;
            }
        }
    }
}

@media only screen and (max-width: 450px) {
    .nesgames {
        .filter {
            margin: 0;
            padding: 2px 2px 2px .5rem;

            .options {
                &.expanded {
                    max-height: 35rem;
                }
            }

            .options-toggle {
                margin-top: -1.6rem;
            }
        }
    }
}