@import '../../variables.scss';

body {
    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        text-align: center;
        margin: 0;
        padding: 0;
        user-select: none;
        -moz-user-select: none;

        .dummy {
            height: 35vh;
            transition: height .3s ease;
        }

        &.top {
            .dummy {
                height: 3em;
            }
        }

        /* Legacy css gingham pattern */
        &::after {
            content: "";
            background: repeating-linear-gradient(
                    45deg,
                    rgba(18, 18, 18, 0.75),
                    rgba(18, 18, 18, 0.75) 20em,
                    rgba(0, 0, 0, 0.75) 20em,
                    rgba(0, 0, 0, 0.75) 40em
                ),
                repeating-linear-gradient(
                    135deg,
                    rgba(10, 10, 10, 0.75),
                    rgba(10, 10, 10, 0.75) 20em,
                    rgba(33, 33, 33, 0.75) 20em,
                    rgba(33, 33, 33, 0.75) 40em
                ),
                rgba(0, 100, 255, 0.4);
            background-attachment: fixed;
            background-position: left top;
            position: fixed;
            left: calc(100% - 100vw);
            top: 0;
            width: 100vw;
            height: 100vh;
            z-index: -2;
        }
    }
}

@media only screen and (max-width: 450px) {
    body {
        .wrapper {
            .dummy {
                display: none;
            }
        }
    }
}