$phase1: #F42800;
$phase2: #0072FF;
$phase3: #FF0098;
$phase4: #0fc92e;

$shadow-color: #000;
$frame: drop-shadow(1px 1px 0 #F2B6AB) drop-shadow(1px 1px .5px #4C0A00);

@keyframes neon-flicker-alternate {
	0% { filter: $frame drop-shadow(2px 2px .1px $phase1) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em $phase1); /* on */}
	4% { filter: $frame drop-shadow(2px 2px .1px $phase1) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em $phase1); /* on */}
	4.3% { filter: $frame drop-shadow(2px 2px .1px transparent) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em transparent); /* off */}
	4.6% { filter: $frame drop-shadow(2px 2px .1px $phase1) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em $phase1); /* on */}
	6% { filter: $frame drop-shadow(2px 2px .1px rgba($phase1, .5)) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em rgba($phase1, .5)); /* off */}
	6.4% { filter: $frame drop-shadow(2px 2px .1px $phase1) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em $phase1); /* on */}
	10% { filter: $frame drop-shadow(2px 2px .1px $phase1) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em $phase1); /* on */}
	10.2% { filter: $frame drop-shadow(2px 2px .1px rgba($phase1, .5)) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em rgba($phase1, .5)); /* off */}
	10.4% { filter: $frame drop-shadow(2px 2px .1px $phase1) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em $phase1); /* on */}
	10.6% { filter: $frame drop-shadow(2px 2px .1px rgba($phase1, .5)) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em rgba($phase1, .2)); /* off */}
	10.8% { filter: $frame drop-shadow(2px 2px .1px $phase1) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em $phase1); /* on */}
	11% { filter: $frame drop-shadow(2px 2px .1px rgba($phase1, .5)) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em rgba($phase1, .8)); /* off */}
	11.2% { filter: $frame drop-shadow(2px 2px .1px $phase1) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em $phase1); /* on */}
	15% { filter: $frame drop-shadow(2px 2px .1px $phase1) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em $phase1); /* on */}
	15.1% { filter: $frame drop-shadow(2px 2px .1px rgba($phase1, .5)) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em rgba($phase1, .9)); /* off */}
	15.2% { filter: $frame drop-shadow(2px 2px .1px $phase1) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em $phase1); /* on */}
	17.75% { filter: $frame drop-shadow(2px 2px .1px $phase1) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em $phase1); /* on */}
	18% { filter: $frame drop-shadow(2px 2px .1px transparent) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em transparent); /* off */}
	18.5% { filter: $frame drop-shadow(2px 2px .1px $phase1) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em $phase1); /* on */}
	18.75% { filter: $frame drop-shadow(2px 2px .1px transparent) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em transparent); /* off */}
	
	/* Phase 2 */
	22.5% { filter: $frame drop-shadow(2px 2px .1px $phase2) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em $phase2); /* on */}
	22.75% { filter: $frame drop-shadow(2px 2px .1px transparent) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em transparent); /* off */}
	23.25% { filter: $frame drop-shadow(2px 2px .1px $phase2) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em $phase2); /* on */}
	25% { filter: $frame drop-shadow(2px 2px .1px $phase2) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em $phase2); /* on */ }
	30% { filter: $frame drop-shadow(2px 2px .1px rgba($phase2, .5)) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em rgba($phase2, .5)); /* off */}
	34.5% { filter: $frame drop-shadow(2px 2px .1px $phase2) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em $phase2); /* on */}
	34.75% { filter: $frame drop-shadow(2px 2px .1px transparent) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em transparent); /* off */}
	36.25% { filter: $frame drop-shadow(2px 2px .1px $phase2) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .04em $phase2); /* on */}
	36.5% { filter: $frame drop-shadow(2px 2px .1px transparent) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em transparent); /* off */}
	
	/* Phase 3 */
	40.25% { filter: $frame drop-shadow(2px 2px .1px $phase3) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em $phase3); /* on */}
	42.75% { filter: $frame drop-shadow(2px 2px .2px $phase3) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .05em $phase3); /* on */}
	43.5% { filter: $frame drop-shadow(2px 2px .2px $phase3) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .05em $phase3); /* on */}
	44% { filter: $frame drop-shadow(2px 2px .1px transparent) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em transparent); /* off */}
	44.5% { filter: $frame drop-shadow(2px 2px .1px $phase3) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em $phase3); /* on */}
	55% { filter: $frame drop-shadow(2px 2px .1px $phase3) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em $phase3); /* on */}
	55.25% { filter: $frame drop-shadow(2px 2px .1px transparent) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .02em transparent); /* off */}
	55.5% { filter: $frame drop-shadow(2px 2px .1px $phase3) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em $phase3); /* on */}
	55.75% { filter: $frame drop-shadow(2px 2px .1px transparent) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em transparent); /* off */}
	56.25% { filter: $frame drop-shadow(2px 2px .1px $phase3) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em $phase3); /* on */}
	56.5% { filter: $frame drop-shadow(2px 2px .1px transparent) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em transparent); /* off */}
	
	/* Phase 4*/
	60.25% { filter: $frame drop-shadow(2px 2px .1px $phase4) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .01em $phase4); /* on */}
	60.5% { filter: $frame drop-shadow(2px 2px .1px transparent) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .01em transparent); /* off */}
	61% { filter: $frame drop-shadow(2px 2px .1px $phase4) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .01em $phase4); /* on */}
	62.75% { filter: $frame drop-shadow(2px 2px .1px $phase4) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .01em $phase4); /* on */}
	66.25% { filter: $frame drop-shadow(2px 2px .1px $phase4) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .01em $phase4); /* on */}
	66.5% { filter: $frame drop-shadow(2px 2px .1px transparent) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .01em transparent); /* off */}
	67% { filter: $frame drop-shadow(2px 2px .1px $phase4) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .01em $phase4); /* on */}
	71.25% { filter: $frame drop-shadow(2px 2px .1px $phase4) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .01em $phase4); /* on */}
	71.5% { filter: $frame drop-shadow(2px 2px .1px transparent) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .01em transparent); /* off */}
	72% { filter: $frame drop-shadow(2px 2px .1px $phase4) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .01em $phase4); /* on */}
	75.5%  {filter: $frame drop-shadow(2px 2px .1px $phase4) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .01em $phase4); /* on */}
	75.75% { filter: $frame drop-shadow(2px 2px .1px transparent) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .01em transparent); /* off */}
	76.25% { filter: $frame drop-shadow(2px 2px .1px $phase4) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .01em $phase4); /* on */}
	76.5% { filter: $frame drop-shadow(2px 2px .1px transparent) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em transparent); /* off */}
	
	/* Phase 1 */
	80.25% { filter: $frame drop-shadow(2px 2px .1px $phase1) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em $phase1); /* on */}
	80.5% { filter: $frame drop-shadow(2px 2px .1px transparent) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em transparent); /* off */}
	81% { filter: $frame drop-shadow(2px 2px .1px $phase1) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em $phase1); /* on */}
	100% { filter: $frame drop-shadow(2px 2px .1px $phase1) drop-shadow(0 0 5px $shadow-color) drop-shadow(0 0 .03em $phase1); /* on */}
}

@keyframes neon-glow {
	0% { box-shadow: inset 0 7px 10px -10px rgba($phase1, .7); } /* on */
	4% { box-shadow: inset 0 7px 10px -10px rgba($phase1, .7); } /* on */
	4.3% { box-shadow: inset 0 7px 10px -10px transparent; } /* off */
	4.6% { box-shadow: inset 0 7px 10px -10px rgba($phase1, .7); } /* on */
	6% { box-shadow: inset 0 7px 10px -10px transparent; } /* off */
	6.4% { box-shadow: inset 0 7px 10px -10px rgba($phase1, .7); } /* on */
	10% { box-shadow: inset 0 7px 10px -10px rgba($phase1, .7); } /* on */
	10.2% { box-shadow: inset 0 7px 10px -10px transparent; } /* off */
	10.4% { box-shadow: inset 0 7px 10px -10px rgba($phase1, .7); } /* on */
	10.6% { box-shadow: inset 0 7px 10px -10px transparent; } /* off */
	10.8% { box-shadow: inset 0 7px 10px -10px rgba($phase1, .7); } /* on */
	11% { box-shadow: inset 0 7px 10px -10px transparent; } /* off */
	11.2% { box-shadow: inset 0 7px 10px -10px rgba($phase1, .7); } /* on */
	15% { box-shadow: inset 0 7px 10px -10px rgba($phase1, .7); } /* on */
	15.1% { box-shadow: inset 0 7px 10px -10px transparent; } /* off */
	15.2% { box-shadow: inset 0 7px 10px -10px rgba($phase1, .7); } /* on */
	17.75% { box-shadow: inset 0 7px 10px -10px rgba($phase1, .7); } /* on */
	18% { box-shadow: inset 0 7px 10px -10px transparent; } /* off */
	18.5% { box-shadow: inset 0 7px 10px -10px rgba($phase1, .7); } /* on */
	18.75% { box-shadow: inset 0 7px 10px -10px transparent; } /* off */
	
	/* Blue */
	22.5% { box-shadow: inset 0 7px 10px -10px rgba($phase2, .7); } /* on */
	22.75% { box-shadow: inset 0 7px 10px -10px transparent; } /* off */
	23.25% { box-shadow: inset 0 7px 10px -10px rgba($phase2, .7); } /* on */
	25% { box-shadow: inset 0 7px 10px -10px rgba($phase2, .7); } /* on */
	30% { box-shadow: inset 0 7px 10px -10px transparent; } /* off */
	34.5% { box-shadow: inset 0 7px 10px -10px rgba($phase2, .7); } /* on */
	34.75% { box-shadow: inset 0 7px 10px -10px transparent; } /* off */
	36.25% { box-shadow: inset 0 7px 10px -10px rgba($phase2, .7); } /* on */
	36.5% { box-shadow: inset 0 7px 10px -10px transparent; } /* off */
	
	/* Pink */
	40.25% { box-shadow: inset 0 7px 10px -10px rgba($phase3, .7); } /* on */
	42.75% { box-shadow: inset 0 7px 10px -10px rgba($phase3, .7); } /* on */
	43.5% { box-shadow: inset 0 7px 10px -10px rgba($phase3, .7); } /* on */
	44% { box-shadow: inset 0 7px 10px -10px transparent; } /* off */
	44.5% { box-shadow: inset 0 7px 10px -10px rgba($phase3, .7); } /* on */
	55% { box-shadow: inset 0 7px 10px -10px rgba($phase3, .7); } /* on */
	55.25% { box-shadow: inset 0 7px 10px -10px transparent; } /* off */
	55.5% { box-shadow: inset 0 7px 10px -10px rgba($phase3, .7); } /* on */
	55.75% { box-shadow: inset 0 7px 10px -10px transparent; } /* off */
	56.25% { box-shadow: inset 0 7px 10px -10px rgba($phase3, .7); } /* on */
	56.5% { box-shadow: inset 0 7px 10px -10px transparent; } /* off */
	
	/* Yellow */
	60.25% { box-shadow: inset 0 7px 10px -10px rgba($phase4, .7); } /* on */
	60.5% { box-shadow: inset 0 7px 10px -10px transparent; } /* off */
	61% { box-shadow: inset 0 7px 10px -10px rgba($phase4, .7); } /* on */
	62.75% { box-shadow: inset 0 7px 10px -10px rgba($phase4, .7); } /* on */
	66.25% { box-shadow: inset 0 7px 10px -10px rgba($phase4, .7); } /* on */
	66.5% { box-shadow: inset 0 7px 10px -10px transparent; } /* off */
	67% { box-shadow: inset 0 7px 10px -10px rgba($phase4, .7); } /* on */
	71.25% { box-shadow: inset 0 7px 10px -10px rgba($phase4, .7); } /* on */
	71.5% { box-shadow: inset 0 7px 10px -10px transparent; } /* off */
	72% { box-shadow: inset 0 7px 10px -10px rgba($phase4, .7); } /* on */
	75.5%  { box-shadow: inset 0 7px 10px -10px rgba($phase4, .7); } /* on */
	75.75% { box-shadow: inset 0 7px 10px -10px transparent; } /* off */
	76.25% { box-shadow: inset 0 7px 10px -10px rgba($phase4, .7); } /* on */
	76.5% { box-shadow: inset 0 7px 10px -10px transparent; } /* off */
	
	/* Red */
	80.25% { box-shadow: inset 0 7px 10px -10px rgba($phase1, .7); } /* on */
	80.5% { box-shadow: inset 0 7px 10px -10px transparent; } /* off */
	81% { box-shadow: inset 0 7px 10px -10px rgba($phase1, .7); } /* on */
	100% { box-shadow: inset 0 7px 10px -10px rgba($phase1, .7); } /* on */
}