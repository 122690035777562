@import "../../variables";

nav {
    .nav {
        > li {
            padding: 0;
            margin: 0;

            ul {
                li {
                    position: relative;
                }
            }

            .navbutton {
                position: relative;
                display: inline-block;
                padding: 0.4em;

                background: rgba(18, 34, 49, 0.9);
                background: linear-gradient(
                    to bottom,
                    rgba(18, 34, 49, 0.75) 0%,
                    rgba(18, 34, 49, 0.75) 49%,
                    rgba(11, 21, 31, 0.75) 51%,
                    rgba(18, 34, 49, 0.75) 100%
                );

                font-family: mastodb;
                color: $font-color;
                font-size: 2rem;
                line-height: 1em;
                text-transform: uppercase;
                text-shadow: 0 0 5px rgba(0, 0, 0, 1);
                text-decoration: none;
                cursor: pointer;

                outline: 2px outset rgba(255, 255, 255, 0.1);
                outline-offset: -3px;
                border: 1px solid rgba(0, 0, 0, 0.5);
                box-shadow: inset 0 0 10px 0 rgba(100, 100, 255, 0.1);

                transition: color 0.3s ease-out, transform 0.1s ease;

                & + span {
                    position: absolute;
                    z-index: 1;
                    pointer-events: none;
                    color: white;
                    font-family: Roboto-mono;
                    font-size: 0.8rem;
                    text-shadow: 0 0 5px rgba(0, 0, 0, 1);
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 1);
                    top: calc(100% + 12px);
                    bottom: auto;
                    transform: scale(0);
                    padding: 0.7em;
                    white-space: nowrap;
                    border: 1px solid rgba(255, 255, 255, 0.5);
                    border-radius: 3px;
                    background: rgba(0, 0, 0, 0.8);
                    text-align: center;
                    transition: transform 0.05s 0s linear;

                    &.flip {
                        bottom: calc(100% + 12px);
                        top: unset;
                    }

                    svg {
                        vertical-align: text-bottom;
                        margin-left: 3px;
                        display: inline-block;
                        width: auto;
                        height: 1em;
                    }
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: -1;
                    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.9);
                }

                &::after {
                    --requiredAlpha: 1; // This is for opacity disabling set from _variables.scss
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 0%;
                    height: 100%;
                    background-color: rgba(255, 255, 255, 1);
                    opacity: 0.4;
                    background-image: linear-gradient(
                        135deg,
                        rgba(255, 255, 255, var(--requiredAlpha)) 0%,
                        rgba(255, 255, 255, var(--requiredAlpha)) 50%,
                        rgba(255, 255, 255, 0) 50%
                    );
                    transition: none;
                }

                &:hover,
                &:focus {
                    color: #fff;
                    z-index: 4;
                    transform: scale(1.048);

                    &::after {
                        width: 100%;
                        background-color: rgba(255, 255, 255, 0);
                        opacity: 0.05;
                        transition: all 0.2s ease-out;
                    }
                }

                &:hover {
                    + span {
                        transform: scale(1);
                        transition: transform 0.2s 0.5s ease;

                        &::after {
                            content: "";
                            position: absolute;
                            bottom: 100%;
                            width: 0;
                            height: 0;
                            border: 10px solid transparent;
                            border-bottom-color: rgba(255, 255, 255, 0.5);
                            border-top-color: transparent;
                            margin-bottom: 1px;
                        }

                        &.flip {
                            &::after {
                                top: 100%;
                                bottom: 0;
                                border-bottom-color: transparent;
                                border-top-color: rgba(255, 255, 255, 0.5);
                                margin-top: 1px;
                            }
                        }
                    }
                }
            }

            .menuicon {
                color: transparent;
                width: 1em;
                height: 1em;

                img {
                    display: inline-block;
                    height: 0.8em;
                }

                &:hover {
                    box-shadow: inset 0px -50px 84px -26px rgba(244, 40, 0, 0.9), 0px 0px 15px 0px rgba(244, 40, 0, 0.9);

                    img {
                        filter: brightness(1000%);
                    }
                }
            }

            .hold {
                box-shadow: inset 0px -50px 84px -26px rgba(244, 40, 0, 0.7), 0px 0px 15px 0px rgba(244, 40, 0, 0.7);
                z-index: 4;
                transform: scale(1.048);

                img {
                    filter: brightness(1000%);
                }
            }

            .steam {
                &:hover,
                &:focus {
                    box-shadow: inset 0px -50px 84px -26px rgba(18, 80, 179, 0.9),
                        0px 0px 15px 0px rgba(18, 80, 179, 0.9);
                }

                & + span {
                    left: 0;
                    transform-origin: calc(1em + 10px) top;

                    &::after {
                        left: 1em;
                    }
                }
            }

            .nes {
                &:hover,
                &:focus {
                    box-shadow: inset 0px -50px 84px -26px rgba(255, 0, 0, 0.7), 0px 0px 15px 0px rgba(255, 0, 0, 0.9);
                }

                &.current {
                    box-shadow: inset 0px -50px 84px -26px rgba(255, 0, 0, 0.5), 0px 0px 15px 0px rgba(255, 0, 0, 0.4);
                    color: #fff;
                    pointer-events: none;
                }

                & + span {
                    left: 0;
                    transform-origin: calc(1em + 10px) top;

                    &::after {
                        left: 1em;
                    }
                }
            }

            .twitch {
                &:hover,
                &:focus {
                    box-shadow: inset 0px -50px 84px -26px rgba(106, 0, 244, 0.7),
                        0px 0px 15px 0px rgba(106, 0, 244, 0.9);
                }

                & + span {
                    right: 0;
                    transform-origin: calc(100% - 1em - 10px) top;

                    &::after {
                        right: 1em;
                    }
                }
            }

            .ron {
                &:hover,
                &:focus {
                    box-shadow: inset 0px -50px 84px -26px rgba(109, 193, 0, 0.7),
                        0px 0px 15px 0px rgba(109, 193, 0, 0.6);
                }

                & + span {
                    right: 0;
                    transform-origin: calc(100% - 1em - 10px) top;

                    &::after {
                        right: 1em;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 450px) {
    nav {
        .nav {
            li {
                .navbutton {
                    font-size: 1.8rem;
                }

                .menuicon {
                    position: fixed;
                    z-index: 4;
                    top: 0;
                    left: 0;
                    background: none;
                    border: none;
                    outline: none;
                    box-shadow: none;

                    img {
                        height: 1.2em;
                    }
    
                    &:hover {
                        box-shadow: none;
                    }

                    &::before {
                        display: none;
                    }

                    &::after {
                        display: none;
                    }
                }
    
                .hold {
                    box-shadow: none;
                }
            }
        }
    }
}