.nesgames {
    .additionalinfo {
        margin: 3rem;
        padding: 0;

        list-style-type: none;
    }
}

@media only screen and (max-width: 700px) {
    .nesgames {
        .additionalinfo {
            margin: .5rem;
        }
    }
}