@import '../../header-animation';

header {
	padding: 0;
	color: rgba($phase1, .8);
	cursor: default;
	opacity: .9;
	text-align: center;

    h1 {
        margin: 0 0 -.1em 0;
        padding: 0;
        position: relative;
        display: inline-block;
        font-family: yakari;
        font-size: 7.8rem;
        letter-spacing: -.03em;
        z-index: 2;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 2px white;
        text-fill-color: transparent;
        text-stroke: 2px white;
        font-weight: 500;
    }
}

@supports (-webkit-text-fill-color: transparent) {
    header {
        h1 {
            animation: neon-flicker-alternate 40s ease infinite;
        }
    }
}

@media only screen and (max-width: 450px) {
    header {
        margin-top: 3rem;
        width: 100vw;

        h1 {
            font-size: 25vw;
            letter-spacing: -.01em;
        }
    }
}