@import "../../variables";

$size: 18rem;

.numpad {
    position: relative;
    width: $size;
    height: $size;
    margin-top: 3rem;
    margin-bottom: 3rem;
    filter: drop-shadow(0 0 5px #000);
    animation: 0.2s ease 0s 1 fade;

    .border {
        box-sizing: border-box;
        position: absolute;
        top: -0.1rem;
        left: -0.1rem;
        width: $size + 0.2;
        height: $size + 0.2;
        border: 0.2rem solid rgba(#000, 1);
        border-radius: 50%;
        box-shadow: inset 0 0 1px 1px rgba(#000, 0.4);
        pointer-events: none;
        z-index: 10;
    }

    .timer {
        width: $size;
        height: $size;
        position: absolute;
        top: 0;
        left: 50%;
        background: #14171b;
        transform: translateX(-50%);
        border-radius: 50%;

        .fill {
            position: absolute;
            top: 0;
            left: 0;
            width: $size;
            height: $size;
            border-radius: 50%;
            background: $font-color;
            clip-path: polygon(100% 0, 50% 0, 50% 100%, 100% 100%);
            z-index: 1;
        }

        .left50 {
            position: absolute;
            top: 0;
            left: 0;
            width: $size;
            height: $size;
            border-radius: 50%;
            background: $font-color;
            clip-path: polygon(0 0, 50% 0, 50% 100%, 0% 100%);
            z-index: 3;
        }

        .right50 {
            position: absolute;
            top: 0;
            left: 0;
            width: $size;
            height: $size;
            background: #14171b;
            clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
            border-radius: 50%;
            z-index: 4;
        }

        .ceiling {
            position: absolute;
            top: 0;
            left: 0;
            width: $size;
            height: $size;
            border-radius: 50%;
            background: rgb(255, 51, 0);
            clip-path: polygon(50% 50%, 49% 0, 51% 0);
            z-index: 7;
        }
    }

    .display {
        box-sizing: border-box;
        width: $size - 1.2;
        height: $size - 1.2;
        position: absolute;
        top: 0.6rem;
        left: 0.6rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        background: #070a0e;
        border-radius: 50%;
        box-shadow: inset 0 0 1rem 0 rgba(#000, 5), 0 0 1px 1px rgba(#000, 0.4);
        overflow: hidden;

        &::before {
            content: "";
            position: absolute;
            top: 10%;
            left: -20%;
            border-radius: 50%;
            width: $size * 3;
            height: $size * 2;
            pointer-events: none;
            box-shadow: 0 0 0 $size rgba(255, 255, 255, 0.01);
            animation: 1s 0s 1 slide;
        }

        .numbers {
            align-self: center;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: Roboto-mono;
            font-variant-numeric: tabular-nums;
            transition: transform 0.5s ease;

            color: rgba($font-color, 1);
            text-shadow: 1px 1px 0 rgba($font-color, 0.5);

            .rand1 {
                font-size: 9rem;
                animation: 0.2s 0s 1 grow;
            }

            div {
                display: flex;
                align-items: center;
                text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
                transition: opacity 0.3s ease;

                .rand2 {
                    font-size: 7rem;
                    color: #202327;
                    animation: 0.6s 0s 1 grow;
                }

                .rand3 {
                    font-size: 5rem;
                    color: #14161a;
                    animation: 1s 0s 1 grow;
                }
            }

            &.hide {
                transform: translateX(29%);

                div {
                    opacity: 0;
                }
            }
        }

        .results {
            height: 2rem;
            width: 100%;
            display: flex;
            justify-content: center;
            font-family: yakari;

            span {
                position: absolute;
                left: 50%;
                width: 5rem;
                text-align: center;
                font-size: 0.9rem;
                color: $font-color;
                font-size: 2rem;
                opacity: 0.4;

                &.best {
                    transform: skew(5.5deg) translateX(-105%);
                    animation: flash 2s ease 0s 1;
                }

                &.current {
                    transform: skew(5.5deg) translateX(5%);
                }
            }
        }
    }
}
