@import "../../../variables";

.nesgames {
    table {
        border-collapse: collapse;
        width: 100%;
        height: 1px; // Hack to allow div's height: 100% inside td

        .game {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                bottom: 0.1rem;
                right: 0.5rem;
                border: 0.4rem solid transparent;
                border-top-color: $font-color;
                opacity: 0;
                transition: all 0.2s ease;
            }

            /*&:nth-child(even) {
                background: rgba(255, 255, 255, 0.01);
            }*/

            &.odd {
                background: rgba(255, 255, 255, 0.01);
            } 

            &.expanded {
                &::after {
                    opacity: 0.5;
                    bottom: 0.5rem;
                    transform: scaleY(-100%);
                }
            }

            &:hover,
            &:focus {
                background: rgba(255, 255, 255, 0.03);

                &::after {
                    opacity: 1;
                }
            }
        }
    }
}