$font-color: #b9daeb;
$flavour-color1: #ff5659;
$flavour-color2: #7fffa3;
$flavour-color3: #ffbd4c;
$flavour-color4: #d9ff00;

@keyframes fade {
    0% { opacity: 0 }
    100% { opacity: 1 }
}

@keyframes slide {
    0% { transform: translateX(-100%) }
    100% { transform: translateX(0%) }
}

@keyframes grow {
    0% { transform: scale(0) }
    100% { transform: scale(100%) }
}

@keyframes push {
    0% { transform: translateY(200%) }
    100% { transform: translateY(0%) }
}

@keyframes push-with-skew {
    0% { transform: translateY(300%) skew(-10deg) }
    100% { transform: translateY(0%) skew(-10deg) }
}

@keyframes flash {
    0% { color: rgb(0, 255, 0); text-shadow: 0 0 1rem rgb(150, 255, 150) }
    100% { color: inherit; text-shadow: unset }
}

@keyframes highlight {
    0% { box-shadow: inset 0px 0px 0px 20rem rgba(255, 255, 255, .2); }
    100% { box-shadow: inset 0px 0px 0px 20rem rgba(255, 255, 255, 0); }
}

.lowQuality,
.lowQuality *,
.lowQuality *::before,
.lowQuality *::after {
    animation: none !important;
    transition: none !important;
    opacity: 1 !important;
    --alpha: 1 !important;
    --requiredAlpha: 0 !important;
}