nav {
    display: inline-block;
    position: relative;

    ul.nav, ul.navbuttons, ul.subnav {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        position: relative;
    }
}

@supports (pointer-events: none) and (-webkit-text-fill-color: transparent) {
    nav {
        & > ul:first-child {
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 1px;
                right: 1px;
                height: 100%;
                box-shadow: inset 0 7px 10px -10px rgba(255, 40, 0, 0.7);
                pointer-events: none;
                animation: neon-glow 40s ease infinite;
            }
        }
    }
}

@media only screen and (max-width: 450px) {
    nav {
        position: unset;

        .nav {
            position: unset;

            ul.navbuttons {
                width: 99vw;
                flex-wrap: wrap;
            }
        }

        & > ul:first-child {
            &::after {
                display: none;
            }
        }
    }
}