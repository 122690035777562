@import "../../../variables";

.nesgames {
    .filter {
        div {
            h3 {
                margin: 0;
                font-weight: unset;
                font-size: .9rem;
            }

            ul {
                list-style-type: none;
                margin: 0 5rem 1.2rem .5rem;
                padding: .4rem 0 0 0;
                // border-left: 2px solid rgba(255, 255, 255, 0.05);

                li {
                    line-height: 1rem;
                    position: relative;
                    // transform: translateY(.45rem);

                    &::before {
                        content: "";
                        position: relative;
                        width: .5rem;
                        height: 1rem;
                        border: 2px solid rgba(255, 255, 255, 0.05);
                        border-top: none;
                        border-right: none;
                        display: inline-block;
                        vertical-align: bottom;
                        transform: translateY(-.4rem)
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 700px) {
    .nesgames {
        .filter {
            div {
                ul {
                    display: flex;

                    li {
                        border-top: 2px solid rgba(255, 255, 255, 0.05);
                        padding-top: 2px;
                        min-width: 8rem;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .nesgames {
        .filter {
            div {
                ul {
                    margin: 0 0 1.2rem 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 450px) {
    .nesgames {
        .filter {
            div {
                ul {
                    flex-direction: column;
                    row-gap: .5rem;

                    li {
                        border: none;
                    }
                }
            }
        }
    }
}