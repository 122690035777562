@import "../../../variables";

.nesgames {
    table {
        .header {
            text-align: left;
            text-transform: capitalize;
            border-bottom: 1px solid rgba(255, 255, 255, .05);
        }
    }
}