@import "../../variables";

nav {
    .subnav {
        position: relative;
        overflow: hidden;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 100%);

        li {
            transition: all 0.2s ease;

            .subbutton {
                position: relative;
                display: block;
                padding: 0.5em 1.5em;
                color: rgba($font-color, 0.9);
                text-decoration: none;
                text-transform: uppercase;
                font-family: Roboto-mono;
                font-size: 0.8rem;

                transition: all 0.2s ease;

                &:hover,
                &:focus {
                    color: #fff;
                    outline: none;
                }

                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 100%;
                    width: 100%;
                    height: 1em;
                    box-shadow: 0 0 2em 0 #285692;
                    opacity: 0;
                    transition: opacity 0.2s ease;
                }

                span {
                    &::before {
                        content: "[";
                        position: absolute;
                        left: -1em;
                        opacity: 0;
                        visibility: hidden;
                        transition: all 0.2s ease;
                    }

                    &::after {
                        content: "]";
                        position: absolute;
                        right: -1em;
                        opacity: 0;
                        visibility: hidden;
                        transition: all 0.2s ease;
                    }
                }

                &.current {
                    color: #fff;

                    &::after {
                        opacity: 0.6;
                    }

                    span {
                        &::before {
                            left: 0.5em;
                            opacity: 1;
                            visibility: visible;
                        }

                        &::after {
                            right: 0.5em;
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }

        @keyframes glow {
            0% {
                left: 0%;
                transform: translateX(0);
                opacity: 0;
            }

            50% {
                opacity: 1;
            }

            100% {
                left: 100%;
                transform: translateX(-100%);
                opacity: 0;
            }
        }

        &::before {
            content: "";
            position: absolute;
            top: calc(100% - 1px);
            width: 20%;
            height: 1px;
            background: linear-gradient(
                90deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(150, 150, 255, 0.3) 50%,
                rgba(0, 0, 0, 0) 100%
            );
            animation: linear 10s 0s glow infinite;
        }

        &::after {
            content: "";
            position: absolute;
            top: calc(100% - 1px);
            width: 100%;
            background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 50, 100, 0.4) 50%, rgba(0, 0, 0, 0) 100%);
            height: 1px;
        }
    }

    .fade {
        opacity: 0.1;
    }
}

@media only screen and (max-width: 450px) {
    nav {
        .subnav {
            li {
                .subbutton {
                    font-size: 1rem;
                }
            }
        }
    }
}