@import "../../../variables";

.diagram {
    line {
        stroke: $font-color;
        stroke-linejoin: round;
        opacity: .75;

        &.amount {
            pointer-events: none;
            stroke: $flavour-color2;
            stroke-width: .1rem;
        }

        &.spent {
            pointer-events: none;
            stroke: $flavour-color1;
            stroke-width: .1rem;
        }

        &.dash {
            stroke-dasharray: 10 5;
            stroke-opacity: .05;
            stroke-width: .1rem;
            pointer-events: none;
            transition: all .05s linear;
        }

        &.guide {
            stroke-opacity: 0;

            &:hover {
                + .dash {
                    stroke-opacity: .5;
                }

                ~ foreignObject .tooltip {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }

    > rect {
        opacity: .75;
        transform: translateY(-0.65rem);
        
        &.amount {
            fill: $flavour-color2;
            transform: translate(2rem, 1rem);
            width: 0.75rem;
            height: 0.75rem;
        }

        &.spent {
            fill: $flavour-color1;
            transform: translate(12rem, 1rem);
            width: 0.75rem;
            height: 0.75rem;
        }
    }

    .guide ~ foreignObject { // stupid ~ shit because of edge/chrome
        pointer-events: none;
        width: 100%;
        height: 100%;

        .tooltip {
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
            list-style-type: none;
            margin: 0;
            padding: 0.7rem;
            color: white;
            font-family: Roboto-mono;
            font-size: .7rem;
            text-shadow: 0 0 5px rgba(0, 0, 0, 1);
            box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 1);
            white-space: nowrap;
            text-align: left;
            border: 1px solid rgba(255, 255, 255, 0.5);
            border-radius: 3px;
            background: rgba(0, 0, 0, 0.8);
            display: inline-block;
            transition: transform .1s ease, opacity .2s ease .2s;

            &::after {
                content: "";
                position: absolute;
                bottom: 100%;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 0;
                border: 10px solid transparent;
                border-bottom-color: rgba(255, 255, 255, 0.5);
                border-top-color: transparent;
                margin-bottom: 1px;
            }
        }
    }

    text {
        font-size: .9rem;
        fill: $font-color;
        opacity: .75;
        position: relative;
        
        &.rotate {
            writing-mode: vertical-lr;
        }

        &.amount {
            transform: translate(3.1rem, 1rem);
        }

        &.spent {
            transform: translate(13.1rem, 1rem);
        }
    }
}

@media only screen and (max-width: 500px) {
    .diagram {
        text {
            font-size: .8rem;

            &.odd {
                visibility: hidden;
            }
        }
    }
}