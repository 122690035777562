@import './variables';

@font-face {
    font-family: yakari;
    src: url("./assets/yukari.ttf");
}

@font-face {
    font-family: mastodb;
    src: url("./assets/mastodb_dec.otf");
}

@font-face {
    font-family: Roboto;
    src: url("/assets/Roboto-Light.ttf");
}

@font-face {
    font-family: Roboto-mono;
    src: url("/assets/Roboto-Mono.ttf");
}

html {
    
    padding: 0;
    margin: 0;
    margin-left: calc(100vw - 100%);
}

body {
    margin: 0;
    padding: 0;
    user-select: none;
    -moz-user-select: none;
    background: #030509;
}

main {
    display: flex;
    justify-content: center;
    font-family: Roboto;
    font-size: 0.9rem;
    color: $font-color;
    position: relative;
    min-width: 25rem;
}

@media only screen and (max-width: 25rem) {
    main {
        min-width: 100vw;
    }
}