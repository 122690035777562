@import "../../../variables";

.nesgames {
    table {
        .header {
            th {
                padding: 0 2rem 0.5rem 0;
                position: relative;
                text-transform: uppercase;
                letter-spacing: 0.1rem;
                overflow: hidden;
                transition: all .2s ease;

                &::after {
                    content: "";
                    position: absolute;
                    right: 0.5rem;
                    top: 0.4rem;
                    opacity: 0;
                    border: 0.4rem solid transparent;
                    border-top-color: $font-color;
                    transition: all 0.2s ease;
                }

                &:hover {
                    box-shadow: inset 0 -2px 0 -1px rgba($font-color, .5);
                }

                &:hover,
                &.selected {
                    &::after {
                        opacity: 1;
                    }
                }

                &.selected {
                    &:hover {
                        &::after {
                            transform: scaleY(-100%);
                            top: 0;
                        }
                    }
                }

                &.reverse {
                    &::after {
                        opacity: 1;
                        top: 0;
                        transform: scaleY(-100%);
                    }

                    &:hover {
                        &::after {
                            transform: unset;
                            top: 0.4rem;
                        }
                    }
                }
            }

            &::before {
                content: "";
                display: block;
                min-width: 1.5em;
                margin: 0 1rem;
                padding: 0.2rem 0;
            }
        }
    }
}

@media only screen and (max-width: 700px) {
    .nesgames {
        table {
            .header {
                th {
                    padding: 0 1rem 0.5rem 0;
                    font-size: .7rem;
                    letter-spacing: 2px;

                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    .nesgames {
        table {
            .header {
                th {
                    &:first-child {
                        padding-left: .5rem;
                    }
                }
                
                &::before {
                    display: none;
                }
            }
        }
    }
}