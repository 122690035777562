@import "../../variables";

.menu {
    visibility: hidden;
    overflow: hidden;
    opacity: 0;
    z-index: 3;
    transform: scale(0);
    transform-origin: top left;

    position: absolute;
    top: 100%;
    text-shadow: 0 0 5px rgba(0, 0, 0, 1);

    display: flex;

    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    color: $font-color;
    font-family: Roboto;
    font-size: 1rem;
    text-align: left;

    transition: opacity 0.1s ease, transform 0.1s ease, visibility 0.1s;

    > ul {
        --gradient: to right, rgba(0, 0, 0, .5) -20%, rgba(0, 0, 0, .8) 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        gap: 2px;
        margin: 0;
        padding: .7rem 0;
        list-style-type: none;

        li {
            --alpha: 0;
            background-color: rgba(0, 0, 0, var(--alpha)); // For lowQuality setting
            background-image: linear-gradient(var(--gradient));
            border-radius: 3px 0 0 3px;
            padding: .2rem;
            cursor: pointer;
            transition: all .2s ease;

            &.active {
                --color: #fff;

                svg {
                    opacity: 1;
                    fill: var(--color);
                }

                &::before {
                    content: "";
                    width: 2px;
                    height: 100%;
                    background: var(--color);
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }

            svg {
                margin: .3rem .5rem;
                display: block;
                width: 2rem;
                height: 2rem;
                fill: $font-color;
                opacity: .6;
                filter: drop-shadow(0 0 5px #000);
                transition: all .2s ease;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    .context {
        --alpha: 0.9; // This is for opacity disabling set from _variables.scss
        box-shadow: inset 0px 0px 0px 2px rgba(44, 52, 67, 0.5);
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 3px;
        padding: 1rem;
        background-color: rgba(0, 0, 2, var(--alpha));
        margin-top: 3px;
        position: relative;
        overflow: hidden;
        width: 100%;
        max-height: 2000px;
        font-size: .9rem;
        transition: max-height 1s ease;

        p {
            margin: 0 0 1rem 0;
            text-align: justify;
            text-align-last: left;
            text-shadow: 1px 1px 0 black;
        }
    
        .copy {
            font-size: .75rem;
            position: absolute;
            bottom: 1rem;
            right: 1rem;
            color: rgba(44, 52, 67, .7);
            border-width: 0 0 1px 0;
            border-style: solid;
            border-image-slice: 1;
            border-image-source: linear-gradient(90deg, transparent 0%, rgba(44, 52, 67, .6) 50%, transparent 100%);
        }
    
        &::before {
            --requiredAlpha: 1;
            content: "";
            position: absolute;
            z-index: 0;
            top: 0; left: 0;
            width: 0%;
            height: 100%;
            background-color: rgba(255, 255, 255, 1);
            opacity: .2;
            background-image: linear-gradient(135deg, rgba(255, 255, 255, var(--requiredAlpha)) 0%, rgba(255, 255, 255, var(--requiredAlpha)) 50%, rgba(255, 255, 255, 0) 50%);
            transition: none;
        }
    }

    &.expanded {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
    
        div {
            &::before {
                width: 100%;
                background-color: rgba(255, 255, 255, 0);
                opacity: .03;
                transition: all 0.2s ease-out;
            }
        }
    }
    
}

@media only screen and (max-width: 450px) {
    .menu {
        position: fixed;
        top: 0;
        left: 0;
        flex-direction: column;
        height: 100vh;

        > ul {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding: 0;
            background-image: linear-gradient(var(--gradient));

            li {
                padding-left: .7rem;
                padding-right: .7rem;

                &.active {
                    &::before {
                        width: 100%;
                        height: 2px;
                        top: unset;
                        bottom: 0;
                    }
                }

                svg {
                    width: 2.5rem;
                    height: 2.5rem;
                }
            }
        }

        .context {
            background-color: rgba(0, 0, 0, 0.9);
            box-sizing: border-box;
            width: 100vw;
            margin: 0;
            z-index: 1;
            flex-grow: 100;
            font-size: 1.1rem;
        }
    }
}