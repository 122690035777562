@import "../../../variables";

.nesgames {
    table {
        tr {
            height: 100%; // Hack to allow div's height: 100% inside td
            box-shadow: inset 0 0 0 1px transparent;
            scroll-margin-top: 5rem;
            transition: box-shadow 0.2s ease;

            &.game {
                counter-increment: count;
                contain: strict;

                td {
                    height: 100%; // Hack to allow div's height: 100% inside td
                    white-space: nowrap;
                    vertical-align: top;

                    > div {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 100%;
                        max-height: 15rem;
                        padding: .2rem .5rem .2rem 0;
                        overflow: hidden;

                        a {
                            position: absolute;
                            left: .2rem;
                            opacity: 0;
                            visibility: hidden;
                            transition: all .2s ease;
                            
                            svg {
                                width: .9rem;
                                height: .9rem;
                                fill: rgba($font-color, .5);
                                transition: all .2s ease;
                            }

                            &:not(.active):hover, &.active {
                                svg {
                                    fill: rgba($font-color, 1);
                                }
                            }

                            &.active:hover {
                                svg {
                                    fill: rgba($font-color, .5);
                                }
                            }
                        }

                        > span {
                            display: flex;

                            span {
                                display: block;
                                box-sizing: border-box;
                                max-width: 0.5rem;
                                overflow: hidden;
                                text-transform: capitalize;
                                letter-spacing: 0.1px;
                                color: transparent;
                                transition: max-width 0.2s steps(20, end), margin-right 0.2s steps(20, end), color 0.2s steps(1, end);

                                &:first-letter {
                                    color: $font-color;
                                }
                            }

                            &:hover,
                            &.expanded {
                                span {
                                    margin-right: 0.2rem;
                                    max-width: 9.5rem;
                                    color: unset;
                                    transition: max-width 0.2s steps(20, end), margin-right 0.2s steps(20, end), color 0s linear;
                                }
                            }
                        }

                        .details {
                            box-sizing: border-box;
                            max-height: 0;
                            opacity: 0;
                            visibility: hidden;
                            transition: all 0.3s ease;

                            ul {
                                margin: 0 0 1rem 0;
                                padding: 0;
                                list-style-type: none;

                                li {
                                    padding: 0.1rem 0;
                                }

                                &.set {
                                    display: grid;
                                    grid-template-columns: 1fr 1fr;
                                    grid-gap: 0.5rem;

                                    li {
                                        position: relative;
                                        border: 2px solid rgba(50, 50, 50, 0.2);
                                        border-radius: 2px;
                                        background: rgb(20, 20, 20);
                                        background: linear-gradient(
                                            180deg,
                                            rgb(30, 30, 30) 0%,
                                            rgb(30, 30, 30) 54%,
                                            rgb(20, 20, 20) 54%,
                                            rgb(20, 20, 20) 100%
                                        );
                                        background-clip: padding-box;
                                        box-shadow: inset 0 0 1.5rem 0 rgba(50, 60, 70, 0.6);
                                        padding: 0.3rem;
                                        text-align: center;
                                        text-transform: uppercase;
                                        font-size: 0.8rem;
                                        letter-spacing: 1px;

                                        &::before,
                                        &::after {
                                            content: "";
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            right: 0;
                                            bottom: 0;
                                        }

                                        &::before {
                                            opacity: 0.2;
                                        }

                                        &::after {
                                            --requiredAlpha: 0.03; // This is for opacity disabling set from _variables.scss
                                            background: linear-gradient(
                                                135deg,
                                                rgba(255, 255, 255, var(--requiredAlpha)) 0%,
                                                rgba(255, 255, 255, var(--requiredAlpha)) 50%,
                                                transparent 50%,
                                                transparent 100%
                                            );
                                        }

                                        &.horrible {
                                            &::before {
                                                background: darken($flavour-color1, 20%);
                                                background: linear-gradient(
                                                    to right,
                                                    rgba(darken($flavour-color1, 20%), 0.5) 0%,
                                                    darken($flavour-color1, 20%) 100%
                                                );
                                                width: 10%;
                                            }
                                        }

                                        &.poor {
                                            &::before {
                                                background: darken($flavour-color3, 20%);
                                                background: linear-gradient(
                                                    to right,
                                                    rgba(darken($flavour-color3, 20%), 0.5) 0%,
                                                    darken($flavour-color3, 20%) 100%
                                                );
                                                width: 34%;
                                            }
                                        }

                                        &.average {
                                            &::before {
                                                background: darken($flavour-color4, 10%);
                                                background: linear-gradient(
                                                    to right,
                                                    rgba(darken($flavour-color4, 10%), 0.5) 0%,
                                                    darken($flavour-color4, 10%) 100%
                                                );
                                                width: 67%;
                                            }
                                        }

                                        &.good {
                                            &::before {
                                                background: darken($flavour-color2, 30%);
                                                background: linear-gradient(
                                                    to right,
                                                    rgba(darken($flavour-color2, 30%), 0.8) 0%,
                                                    darken($flavour-color2, 30%) 100%
                                                );
                                                width: 100%;
                                            }
                                        }
                                    }
                                }
                            }

                            .super {
                                vertical-align: super;
                                color: rgba($font-color, 0.5);
                                font-size: 0.7rem;
                                margin-left: 0.2rem;
                            }
                        }
                    }

                    &:last-child {
                        white-space: unset;
                        width: 100%;
                        padding-right: 1rem;
                    }
                }

                .checkmark {
                    position: relative;
                    border-radius: 1px;
                    background: rgb(20, 20, 20);
                    height: 1rem;
                    width: 1rem;
                    padding: 0;
                    margin-top: 0.25rem;
                    background: linear-gradient(
                        180deg,
                        rgba(255, 255, 255, 0.01) 0%,
                        rgba(255, 255, 255, 0.03) 50%,
                        transparent 50%,
                        transparent 100%
                    );

                    &::before {
                        content: "";
                        position: absolute;
                        top: 2px;
                        right: 2px;
                        bottom: 2px;
                        left: 2px;
                        border-radius: 1px;
                        outline: 1px solid rgba(0, 0, 0, 0.1);
                        outline-offset: -1px;
                        background: rgba(255, 255, 255, 0.1);
                    }

                    &.passed {
                        &::before {
                            box-shadow: inset 0 0 0.7rem 0 rgba(darken($flavour-color2, 20%), 0.8);
                        }
                    }

                    &.unpassed {
                        &::before {
                            box-shadow: inset 0 0 0.7rem 0 rgba(darken($flavour-color1, 20%), 0.5);
                        }
                    }

                    &::after {
                        --requiredAlpha: 1; // This is for opacity disabling set from _variables.scss
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        opacity: 0.05;
                        background: linear-gradient(135deg, rgba(255, 255, 255, var(--requiredAlpha)) 0%, rgba(255, 255, 255, var(--requiredAlpha)) 50%, transparent 50%, transparent 100%);
                    }
                }

                &::before {
                    content: counter(count);
                    text-align: right;
                    display: block;
                    min-width: 1.5rem;
                    margin: 0 0.75rem 0 1.2rem;
                    padding: 0.2em 0;
                    opacity: 0.5;
                }
            }

            &.collapse {
                counter-increment: none;
                visibility: hidden;

                .checkmark {
                    display: none;
                }

                td {
                    padding-top: 0;
                    padding-bottom: 0;

                    > div {
                        padding-top: 0;
                        padding-bottom: 0;
                        opacity: 0;
                        overflow: hidden;
                        max-height: 0;

                        > span {
                            display: none;
                        }
                    }
                }

                &::before {
                    max-height: 0;
                    overflow: hidden;
                    font-size: 0;
                }
            }

            &.expanded {
                box-shadow: inset 0 0 0 1px rgba($font-color, 0.1);
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.01) 0%, rgba(255, 255, 255, 0.01) 50%, transparent 50%, transparent 100%);

                &.game {
                    a {
                        opacity: 1;
                        visibility: visible;
                    }

                    .details {
                        max-height: 10rem;
                        opacity: 1;
                        visibility: unset;
                    }
                }
            }

            &.highlight {
                animation: 3s ease .5s 1 highlight;
            }
        }
    }
}

@media only screen and (max-width: 700px) {
    .nesgames {
        table {
            tr {
                &.game {
                    td {
                        > div {
                            padding-right: 5px;

                            > span {
                                &:hover,
                                &.expanded {
                                    span {
                                        margin-right: unset;
                                        max-width: 0.5rem;
                                        color: transparent;
                                    }
                                }
                            }

                            .details {
                                min-height: 0;

                                ul {
                                    &.info {
                                        margin-top: 2rem;
                                        position: absolute;
                                    }

                                    &.set {
                                        position: absolute;
                                        left: 4rem;
                                        top: 1.7rem;
                                        grid-template-columns: none;
                                        grid-gap: 0.1rem;
                                        display: flex;
                                    }
                                }
                            }
                        }
                    }

                    &::before {
                        min-width: .5rem;
                    }
                }

                &.expanded {
                    &.game {
                        .details {
                            min-height: 6.5rem;
                        }
                    }
                }
            }
        }
    }          
}

@media only screen and (max-width: 400px) {
    .nesgames {
        table {
            tr {
                &.game {
                    td {
                        > div {
                            .details {
                                ul {
                                    &.set {
                                        left: .5rem;
                                    }
                                }
                            }

                            a {
                                bottom: .1rem;
                            }
                        }

                        &:first-child {
                            padding-left: .5rem;
                        }
                    }

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}