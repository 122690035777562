@import "../../variables";

.nesgames {
    --alpha: 0.5; // This is for opacity disabling set from _variables.scss
    margin-top: 1rem;
    text-shadow: 0 0 5px rgba(0, 0, 0, 1);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5), inset 0px 0px 0px 2px rgba(44, 52, 67, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    background-color: rgba(0, 0, 0, var(--alpha));
    box-sizing: border-box;
    font-size: .8rem;
    font-family: Roboto-mono;
    margin-bottom: 5rem;
    padding: 1rem 2px;
    text-align: left;
    animation: .2s ease 0s 1 fade;

    .title {
        display: flex;
        margin: 0 1rem;
        justify-content: space-between;
        align-items: center;

        h1 {
            display: flex;
            align-items: center;
            font-size: 1rem;
            letter-spacing: 0.15rem;
            text-transform: uppercase;

            svg {
                display: block;
                height: 1.5rem;
                width: auto;
                margin-right: 1rem;
            }
        }
    }
}

@media only screen and (max-width: 700px) {
    .nesgames {
        font-size: .85rem;
    }
}

@media only screen and (max-width: 550px) {
    .nesgames {
        .title {
            h1 {
                svg {
                    display: none;
                }
            }
        }
    }
}

@media only screen and (max-width: 450px) {
    .nesgames {
        .title {
            flex-direction: column;
        }
    }
}