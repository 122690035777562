.context {
    .settings {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            display: flex;
            justify-content: space-between;
        }
    }
}

@media only screen and (max-width: 450px) {
    .context {
        .settings {
            gap: 1.5rem;
        }
    }
}