@import "../../../variables";

.nesgames {
    .filter {
        div {
            input {
                display: none;
            }

            label {
                position: relative;
                margin: 0;
                color: rgba($font-color, 0.3);
                text-transform: capitalize;
                transition: all 0.2s ease;

                &:hover {
                    color: white;
                }

                .checkmark {
                    display: inline-block;
                    vertical-align: bottom;
                    position: relative;
                    border-radius: 1px;
                    background: rgb(20, 20, 20);
                    height: 1rem;
                    width: 1rem;
                    padding: 0;
                    margin: .1rem .2rem 0 0;
                    background: linear-gradient(
                        180deg,
                        rgba(255, 255, 255, 0.01) 0%,
                        rgba(255, 255, 255, 0.03) 50%,
                        transparent 50%,
                        transparent 100%
                    );

                    &::before {
                        content: "";
                        position: absolute;
                        top: 2px;
                        right: 2px;
                        bottom: 2px;
                        left: 2px;
                        border-radius: 1px;
                        outline: 1px solid rgba(0, 0, 0, 0.1);
                        outline-offset: -1px;
                        background: rgba(255, 255, 255, 0.1);
                        transition: box-shadow .1s ease;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        opacity: 0.05;
                        background: linear-gradient(135deg, #fff 0%, #fff 50%, transparent 50%, transparent 100%);
                    }
                }

                &.checked {
                    color: rgba($font-color, 0.9);

                    &:hover {
                        color: white;
                    }

                    .checkmark {
                        &::before {
                            box-shadow: inset 0 0 0.7rem 0 rgba(150, 200, 255, 0.7);
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 700px) {
    .nesgames {
        .filter {
            font-size: 1rem;

            div {
                label {
                    .checkmark {
                        width: 1.3rem;
                        height: 1.3rem;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .nesgames {
        .filter {
            div {
                label {
                    .checkmark {
                        width: 1.3rem;
                        height: 1.3rem;
                    }
                }
            }
        }
    }
}