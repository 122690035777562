@import '../../../../variables';

.menu {
    button.toggle {
        --color: 200, 100, 100;
        --rgb: rgb(var(--color));
        --rotation: -90deg;
        --width: 2.5rem;
        --height: 1rem;
        --switchWidth: .8rem;
        width: var(--width);
        height: var(--height);
        border: 1px outset rgba($font-color, .3);
        background: rgba(50, 50, 50, .2);
        background: linear-gradient(var(--rotation), rgba(50, 50, 50, .2) 0%, rgba(var(--color), .1) 100%);
        border-radius: 2px;
        position: relative;
        cursor: pointer;

        &::before {
            content: "";
            position: absolute;
            width: var(--switchWidth);
            height: var(--height);
            box-shadow: inset 0 0 .5rem 0 var(--rgb), 0 0 .7rem 0 var(--rgb);
            // clip-path: inset(-10px 0 -10px 0);
            top: -1px;
            left: 0;
            opacity: .5;
            transition: all .2s ease;
        }

        &.enabled {
            --color: 0, 255, 0;
            --rotation: 90deg;

            &::before {
                transform: translateX(-100%);
                position: absolute;
                left: 100%;
                opacity: .9;
                
            }
        }
    }
}

@media only screen and (max-width: 450px) {
    .menu {
        button.toggle {
            --width: 4rem;
            --height: 1.5rem;
            --switchWidth: 1rem;
        }
    }
}