@import "../../variables";

.hideGUI {
    opacity: 0;
    visibility: hidden !important;
    pointer-events: none;
    transition: all 0.4s ease;

    *::before, *::after {
        visibility: hidden !important;
    }
}

.hideBackground {
    &::after {
        opacity: 0;
        visibility: hidden;
        transition: all 0.4s ease;
    }
}

.clicker {
    margin-top: 3rem;
    width: 25rem;
    animation: 0.2s ease 0s 1 fade;

    .setup {
        .dialog {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 7rem;

            span {
                position: relative;
                display: block;
                font-size: 2rem;
                font-family: yakari;
                transform: skew(6deg);
                letter-spacing: 0.2rem;

                &.best {
                    animation: flash 2s ease 0s 1;
                }
            }

            button {
                --requiredAlpha: 0.1;
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                width: 5rem;
                height: 5rem;
                border: none;
                outline: none;
                border-radius: 50%;
                box-shadow: 0 0 0.5rem 2px rgba(0, 0, 0, 0.9);
                background: rgba($font-color, var(--requiredAlpha));
                animation: 0.2s ease 0s 1 grow;

                img {
                    width: 2rem;
                    margin: auto;
                    display: block;
                    transition: all 0.2s ease;
                }

                &:hover {
                    img {
                        filter: drop-shadow(0 0 2px #fff);
                    }
                }
            }
        }

        .range {
            position: relative;
            display: flex;
            align-items: center;
            animation: 0.5s ease 0s 1 push;

            $track-color: #40617765;
            $thumb-color: rgba($font-color, 0.5);
            $thumb-gradient: linear-gradient(
                135deg,
                rgba($font-color, 0.8) 0%,
                rgba($font-color, 0.9) 50%,
                rgba($font-color, 0.7) 50%,
                rgba($font-color, 0.7) 100%
            );

            $thumb-radius: 1px;
            $thumb-height: 2rem;
            $thumb-width: 0.7rem;
            $thumb-shadow-size: 4px;
            $thumb-shadow-blur: 4px;
            $thumb-shadow-color: rgba(0, 0, 0, 0.4);
            $thumb-border-width: 0px;
            $thumb-border-color: transparent;

            $track-width: 100%;
            $track-height: 0.5rem;
            $track-shadow-size: 1px;
            $track-shadow-blur: 1px;
            $track-shadow-color: rgba(0, 0, 0, 0.4);
            //$track-border-width: 0px;
            //$track-border-color: transparent;

            $track-radius: 0px;
            $contrast: 5%;

            $ie-bottom-track-color: darken($track-color, $contrast);

            input {
                @mixin thumb-shadow($shadow-size, $shadow-blur, $shadow-color) {
                    box-shadow: $shadow-size $shadow-size $shadow-blur 0 $shadow-color;
                }

                @mixin track-shadow($shadow-size, $shadow-blur, $shadow-color) {
                    box-shadow: inset $shadow-size $shadow-size $shadow-blur 0 $shadow-color;
                }

                @mixin track {
                    cursor: default;
                    height: $track-height;
                    transition: all 0.2s ease;
                    width: $track-width;
                }

                @mixin thumb {
                    @include thumb-shadow($thumb-shadow-size, $thumb-shadow-blur, $thumb-shadow-color);
                    background: $thumb-color;
                    background: $thumb-gradient;
                    border: $thumb-border-width solid $thumb-border-color;
                    border-radius: $thumb-radius;
                    box-sizing: border-box;
                    cursor: pointer;
                    height: $thumb-height;
                    width: $thumb-width;
                }

                -webkit-appearance: none;
                background: transparent;
                margin: $thumb-height / 2 0;
                width: $track-width;
                box-sizing: border-box;
                z-index: 1;

                &::-moz-focus-outer {
                    border: 0;
                }

                &:focus {
                    outline: 0;

                    &::-webkit-slider-runnable-track {
                        background: lighten($track-color, $contrast);
                    }

                    &::-ms-fill-lower {
                        background: $track-color;
                    }

                    &::-ms-fill-upper {
                        background: lighten($track-color, $contrast);
                    }
                }

                &::-webkit-slider-runnable-track {
                    @include track;
                    @include track-shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
                    background: $track-color;
                    //border: $track-border-width inset $track-border-color;
                    border-radius: $track-radius;
                }

                &::-webkit-slider-thumb {
                    @include thumb;
                    -webkit-appearance: none;
                    margin-top: ($track-height / 2 - $thumb-height / 2);
                }

                &::-moz-range-track {
                    @include track-shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
                    @include track;
                    background: $track-color;
                    //border: $track-border-width inset $track-border-color;
                    border-radius: $track-radius;
                    height: $track-height / 2;
                }

                &::-moz-range-thumb {
                    @include thumb;
                }

                &::-ms-track {
                    @include track;
                    background: transparent;
                    border-color: transparent;
                    border-width: ($thumb-height / 2) 0;
                    color: transparent;
                }

                &::-ms-fill-lower {
                    @include track-shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
                    background: $ie-bottom-track-color;
                    //border: $track-border-width inset $track-border-color;
                    border-radius: ($track-radius * 2);
                }

                &::-ms-fill-upper {
                    @include track-shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
                    background: $track-color;
                    //border: $track-border-width inset $track-border-color;
                    border-radius: ($track-radius * 2);
                }

                &::-ms-thumb {
                    @include thumb;
                    margin-top: $track-height / 4;
                }
            }

            .steps {
                position: absolute;
                display: flex;
                justify-content: space-between;
                width: 100%;
                height: $track-height;

                div {
                    width: 2px;
                    margin: 0 $thumb-width / 2;
                    height: 100%;
                    background: rgba($font-color, 0.1);
                }
            }
        }

        .blocks {
            $block-opacity: 0.1;
            height: 1rem;
            width: 10rem;
            transform: skew(-10deg);
            animation: 1s ease 0s 1 push-with-skew;
            background: linear-gradient(
                90deg,
                rgba($font-color, $block-opacity) 0%,
                rgba($font-color, $block-opacity) 10%,
                rgba($font-color, 0) 10%,
                rgba($font-color, 0) 20%,
                rgba($font-color, $block-opacity) 20%,
                rgba($font-color, $block-opacity) 30%,
                rgba($font-color, 0) 30%,
                rgba($font-color, 0) 40%,
                rgba($font-color, $block-opacity) 40%,
                rgba($font-color, $block-opacity) 50%,
                rgba($font-color, 0) 50%,
                rgba($font-color, 0) 60%,
                rgba($font-color, $block-opacity) 60%,
                rgba($font-color, $block-opacity) 70%,
                rgba($font-color, 0) 70%,
                rgba($font-color, 0) 80%,
                rgba($font-color, $block-opacity) 80%,
                rgba($font-color, $block-opacity) 90%,
                rgba($font-color, 0) 90%,
                rgba($font-color, 0) 100%
            );
        }
    }

    .disks {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .marker {
            position: absolute;
            width: 0.6rem;
            height: 0.6rem;
            border-radius: 50%;
            background: red;
            transform: translate(-50%, -50%);
            z-index: 11;
            pointer-events: none;
        }

        .hud {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;

            .score {
                position: absolute;
                right: 1rem;
                bottom: 1rem;
                font-size: 3rem;
                font-family: yakari;
                letter-spacing: 0.2rem;
                transform: skew(5.5deg);
            }

            .timer {
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                height: 5px;
                background: $font-color;
            }
        }

        .disk {
            position: absolute;
            border-radius: 50%;
            width: 100px;
            height: 100px;
            z-index: 2;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, .7);
            transition-property: transform, opacity, visibility;

            div {
                position: absolute;
                border-radius: 50%;
                background: #eaffff;
                border: 1em solid #bfd8ff;
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;

                &:first-child {
                    clip-path: polygon(44% 0, 0 0, 0 31%, 52% 52%);
                }

                &:nth-child(2) {
                    clip-path: polygon(42% 0, 100% 0, 100% 10%, 48% 52%);
                }

                &:nth-child(3) {
                    clip-path: polygon(101% 62%, 100% 8%, 48% 50%);
                }

                &:nth-child(4) {
                    clip-path: polygon(100% 60%, 100% 100%, 78% 100%, 48% 48%);
                }

                &:nth-child(5) {
                    clip-path: polygon(0 100%, 80% 100%, 50% 48%);
                }

                &:nth-child(6) {
                    clip-path: polygon(0% 100%, 0 29%, 52% 50%);
                }

                &:only-child {
                    clip-path: none;
                }

                &::after {
                    content: "";
                    position: absolute;
                    z-index: 2;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 50%;
                    background: #ddfcff;
                    width: 70%;
                    height: 70%;
                }
            }

            &.split {
                div {
                    transition: all 1s ease;

                    &:first-child {
                        top: -20%;
                        left: -10%;
                    }

                    &:nth-child(2) {
                        top: -30%;
                        left: 10%;
                    }

                    &:nth-child(3) {
                        top: -2%;
                        left: 45%;
                    }

                    &:nth-child(4) {
                        top: 25%;
                        left: 25%;
                    }

                    &:nth-child(5) {
                        top: 20%;
                    }

                    &:nth-child(6) {
                        top: 5%;
                        left: -10%;
                    }

                    &:only-child {
                        top: auto;
                        left: auto;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 450px) {
    .clicker {
        width: 80vw;
    }
}