@import "../../../variables";

.nesgames {
    .title {
        .search {
            position: relative;
            display: inline-block;
            margin-left: 5px;

            input {
                border: 1px solid rgba(255, 255, 255, 0.06);
                border-radius: 3px;
                background: rgba(255, 255, 255, 0.01);
                color: $font-color;
                padding: .2rem .4rem .2rem 1.5rem;

                &:hover {
                    outline: 1px solid rgba($font-color, .3);
                }

                &:focus,
                &:active {
                    outline: 1px solid rgba($font-color, .6);

                    ~ img {
                        opacity: 1;
                    }
                }
            }

            img {
                position: absolute;
                height: 1.1rem;
                left: .2rem;
                top: .2rem;
                max-height: 2rem;
                opacity: .2;
            }
        }
    }
}

@media only screen and (max-width: 450px) {
    .nesgames {
        .title {
            .search {
                align-self: flex-start;
                margin-left: unset;
            }
        }
    }
}